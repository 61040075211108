#bens-hero {
  min-height: 50rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.hero-col {
  flex: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  margin: auto;
  position: relative;
  overflow-x: hidden;
  border-radius: 2rem;
  height: auto;
}

.hero-col.is-top-col {
  align-items: stretch;
}

.hero-col.is-top-col > .hero-col-box {
  justify-content: center;
}

.hero-col-box h2.is-bigger.highlighted {
  background-color: var(--color-red);
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
}

.hero-col-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgb(29, 116, 141);
  background: linear-gradient(
    180deg,
    rgba(29, 116, 141, 1) 0%,
    rgba(15, 58, 71, 0) 100%
  );
  border-radius: 2rem;
  padding: 1rem;
  margin: 0.5rem;
  width: calc(100% - 24rem);
}

.hero-col-box.is-small {
  margin: 0.5rem;
  width: 24rem;
}

.hero-col-box.has-calculator {
  background: var(--color-transparent);
  padding: 0;
}

.hero-col-box.has-cover {
  background: var(--color-transparent);
  position: relative;
}

.has-cover-filter {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  border-radius: 2rem;
}

.hero-box-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.hero-bens-img {
  margin: 1rem auto;
  height: 30rem;
  width: auto;
  object-fit: contain;
  max-width: 100%;
}

.hero-col-box.has-promotion {
  background: var(--color-transparent);
  color: var(--color-white);
  position: relative;
  padding: 0.5rem;
  overflow: hidden;
  border-radius: 2rem;
}

.hero-col-box.has-not-promotion {
  width: auto;
}

.is-promotion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-blue-dark);
  color: var(--color-white);
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  padding: 1rem;
}

.is-promotion.reverse {
  background: var(--color-green-dark);
}

.is-promotion .is-bigger {
  font-size: 1rem;
}

.has-promotion h3 {
  font-size: 1.25rem;
  margin: 0.25rem auto;
}

.has-promotion p {
  font-size: 0.7rem;
  margin: 0.25rem auto;
}

.has-promotion span {
  font-size: 1.5rem;
  font-weight: bold;
  background-color: var(--color-red);
  padding: 0.5rem 4rem;
  margin: 0.25rem auto;
  border-radius: 2rem;
  letter-spacing: 0.2rem;
}

.has-promotion .reverse span {
  background-color: var(--color-blue-dark);
}

.is-promotion:before,
.is-promotion:after {
  overflow: hidden;
  border-radius: 2rem;
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(
    45deg,
    var(--color-blue),
    var(--color-red-light),
    var(--color-green-dark),
    var(--color-white),
    var(--color-blue-dark),
    var(--color-red),
    var(--color-green),
    var(--color-white)
  );
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite alternate;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.is-promotion:after {
  filter: blur(50px);
  border-radius: 2rem;
}

.hero-col-box h1 {
  color: var(--color-white);
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 0.1rem auto;
  text-align: center;
  max-width: 80%;
  font-weight: 400;
}

.hero-col-box h2 {
  color: var(--color-white);
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 0.1rem auto;
  text-align: center;
  max-width: 80%;
  font-weight: 400;
}

.hero-col-box h2.is-bigger {
  font-size: 2rem;
  font-weight: bold;
}

.hero-row {
  height: auto;
}

.hero-row > .hero-media {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  margin: 0;
}

.hero-row > .hero-title {
  color: var(--color-white);
  font-weight: 400;
  margin: 0.5em auto;
  font-size: 1.5rem;
  line-height: 1.5;
  max-width: 80%;
}

.hero-desc {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: var(--color-blue);
  font-weight: 300;
  max-width: 20rem;
  margin: 0 auto;
}

.hero-desc.is-hidden {
  display: none;
}

.hero-action-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  background-color: var(--color-green-dark);
  border-radius: 1em 2em 1em 1em;
  padding: 0.75em 3em;
  color: var(--color-white);
  margin: 0.25em;
  width: 100%;
  max-width: 21em;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 1px 2px 0 var(--shadow-25);
  transition: all 0.2s linear;
}

.hero-action-btn:hover,
.hero-action-btn:active,
.hero-action-btn:focus {
  box-shadow: 0 2px 4px 0 var(--shadow-50);
  color: var(--color-white);
}

.hero-action-btn.is-red {
  background-color: var(--color-red);
}

.hero-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.5em auto;
  position: relative;
  z-index: 0;
}

.hero-action > a {
  text-decoration: none;
  background-color: var(--color-green-dark);
  border-radius: 1em 2em 1em 1em;
  padding: 0.75em 3em;
  color: var(--color-white);
  margin: 0.25em;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 1px 2px 0 var(--shadow-25);
  transition: all 0.2s linear;
}

.hero-action > a:hover,
.hero-action > a:active,
.hero-action > a:focus {
  box-shadow: 0 2px 4px 0 var(--shadow-50);
}

.hero-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.hero-video > .video-title {
  color: var(--color-white);
  font-weight: 500;
  margin: 0.5em auto;
  font-size: 1.1rem;
  text-align: center;
  max-width: 14rem;
}

#iframe {
  margin: 0.5em auto;
}

.hero-partner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
}

.hero-partner > .partner-title {
  margin-right: 0.5em;
  color: var(--color-white);
}

.trustpilot-widget.is-hero {
  background-color: var(--color-white);
  padding: 1em 2em;
  border-radius: 1em 2em 1em 1em;
  margin: 0.5em auto;
}

#video-action {
  display: flex;
  flex-direction: column;
  max-width: 18rem;
  margin: 1rem auto;
  cursor: pointer;
}

#video-action > img {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

#video-wrapper {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--shadow-50);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#video-wrapper.is-show {
  display: flex;
}

#iframe {
  width: calc(16 * 80px);
  height: calc(9 * 80px);
  padding: 1rem;
}

#video-close-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  background-color: #000;
  margin: 1rem auto;
  overflow: hidden;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

#video-close-btn > svg {
  width: 2rem;
  height: 2rem;
}

/* 2xl */
@media (max-width: 1536px) {
  #iframe {
    width: calc(16 * 70px);
    height: calc(9 * 70px);
  }
}

/* xl */
@media (max-width: 1280px) {
  #iframe {
    width: calc(16 * 60px);
    height: calc(9 * 60px);
  }
}

/* lg */
@media (max-width: 1024px) {
  #bens-hero > .bens-container {
    flex-wrap: wrap;
  }

  .hero-col {
    flex: 100%;
    padding: 1rem;
  }

  #iframe {
    width: calc(16 * 50px);
    height: calc(9 * 50px);
  }
}

/* md */
@media (max-width: 768px) {
  .hero-col-box h2.is-bigger {
    font-size: 1.6rem;
  }

  #iframe {
    width: calc(16 * 40px);
    height: calc(9 * 40px);
  }

  .hero-col {
    flex: 100%;
    padding: 1rem;
    flex-direction: unset;
    flex-wrap: wrap-reverse;
  }

  .hero-col-box {
    flex: 100%;
    width: auto;
  }

  .hero-col-box.is-small {
    flex: 100%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

/* sm */
@media (max-width: 640px) {
  #iframe {
    width: calc(16 * 20px);
    height: calc(9 * 20px);
    margin-right: auto;
    margin-left: auto;
  }

  .hero-col {
    flex: 100%;
    flex-direction: unset;
    flex-wrap: wrap-reverse;
  }

  .hero-action {
    flex-direction: column;
  }

  .hero-partner > .partner-title {
    font-size: calc(100vw / 28);
  }

  #video-action {
    max-width: 100%;
  }
}
